import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

import { Grow, Typography, useTheme, useMediaQuery } from "@mui/material";
import ReactPlayer from "react-player/lazy";
import { useInView } from "react-intersection-observer";

// import BubbleOne from "../../images/quote-images/bubble-1.webp";
// import BubbleTwo from "../../images/quote-images/bubble-2.webp";

import BusinessOne from "../../videos/quotes/business-4-trim.mp4";
// import BusinessTwo from "../../videos/quotes/business-5-trim.mp4";

// quote company images
import BFK from "../../images/quote-images/bfk.jpeg";
import Catapult from "../../images/quote-images/catapult.png";
//import Costain from "../../images/quote-images/costain.png";
import Gammon from "../../images/quote-images/gammon.png";
import SwanseaUni from "../../images/quote-images/swansea-uni.png";
import Ascot from "../../images/quote-images/ascot.svg";

const PREFIX = "Quotes";

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  banner: `${PREFIX}-banner`,
  bannerCard: `${PREFIX}-bannerCard`,
  bannerWrapper: `${PREFIX}-bannerWrapper`,
  titleContainer: `${PREFIX}-titleContainer`,
  title: `${PREFIX}-title`,
  leftPointContainer: `${PREFIX}-leftPointContainer`,
  rightPointContainer: `${PREFIX}-rightPointContainer`,
  point: `${PREFIX}-point`,
  playerContainer: `${PREFIX}-playerContainer`,
  floatingLeftHex: `${PREFIX}-floatingLeftHex`,
  floatingRightHex: `${PREFIX}-floatingRightHex`,
  bubbleLeft: `${PREFIX}-bubbleLeft`,
  bubbleRight: `${PREFIX}-bubbleRight`,
  quoteContainer: `${PREFIX}-quoteContainer`,
  quoteContainerLeft: `${PREFIX}-quoteContainerLeft`,
  quoteContainerRight: `${PREFIX}-quoteContainerRight`,
  quoteText: `${PREFIX}-quoteText`,
  quoteCompanyImage: `${PREFIX}-quoteCompanyImage`,
  quoteCompanyImageContainer: `${PREFIX}-quoteCompanyImageContainer`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",

  [`& .${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "50px 40px 100px 40px",
    width: "100%",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 50px 10px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "30px 15px 70px 15px",
    },
  },

  [`& .${classes.image}`]: {
    width: 300,
  },

  [`& .${classes.banner}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    padding: "0px 200px",
    zIndex: 1,
    position: "relative",
    margin: "-50px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "0px 100px",
      margin: "-30px 0px 0px",
      flexDirection: "column",
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: "0px 250px",
      margin: "-30px 0px 0px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0px 300px",
      margin: "-30px 0px 0px",
    },
  },

  [`& .${classes.bannerCard}`]: {
    padding: 10,
    border: "1px solid #540096",
    width: 400,
    borderRadius: 10,
  },

  [`& .${classes.bannerWrapper}`]: {
    width: "100%",
    position: "relative",
  },

  // QUOTE HEADER
  [`& .${classes.titleContainer}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "90%",
    padding: "0px 10px 4px 10px",
    marginBottom: "60px",
    borderBottom: "10px solid #540096",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      marginBottom: "65px",
    },
  },

  [`& .${classes.title}`]: {
    color: "#540096",
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 34,
    },
  },

  [`& .${classes.leftPointContainer}`]: {
    position: "absolute",
    bottom: -24,
    left: -36,
    width: 40,
    height: 40,
  },

  [`& .${classes.rightPointContainer}`]: {
    position: "absolute",
    bottom: -24,
    right: -36,
    width: 40,
    height: 40,
  },

  [`& .${classes.point}`]: {
    width: 40,
    height: 40,
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 40,
      height: 40,
    },
  },

  // QUOTE BANNER
  [`& .${classes.playerContainer}`]: {
    position: "relative",
    width: 290,
    paddingTop: 80,
    maxWidth: 290,
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 240,
      paddingTop: 60,
    },
  },

  [`& .${classes.floatingLeftHex}`]: {
    width: 350,
    position: "absolute",
    left: 0,
    zIndex: 0,
    top: "50%",
    objectFit: "contain",
    transform: "translate(-20%, -65%)",
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 250,
    },
  },

  [`& .${classes.floatingRightHex}`]: {
    width: 350,
    position: "absolute",
    right: 0,
    zIndex: 0,
    top: "50%",
    objectFit: "contain",
    transform: "translate(30%, -45%)",
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 250,
    },
  },
  //QUOTE CONTAINER
  [`& .${classes.bubbleLeft}`]: {
    height: "185%",
    width: "125%",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: -1,
    minHeight: 300,
    transform: "translate(-13%, -16%)",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      height: "200%",
      width: "125%",
      transform: "translate(-13%, -16%)",
    },
  },

  [`& .${classes.bubbleRight}`]: {
    height: "185%",
    width: "125%",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: -1,
    minHeight: 300,
    transform: "translate(-13%, -16%)",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      height: "200%",
      width: "125%",
      transform: "translate(-13%, -16%)",
    },
  },

  [`& .${classes.quoteContainer}`]: {
    position: "absolute",
    padding: 10,
    top: 0,
    zIndex: 1,
    maxWidth: 300,
    [theme.breakpoints.down("sm")]: {
      width: 260,
    },
    [theme.breakpoints.between("sm", "md")]: {
      top: 40,
    },
  },

  [`& .${classes.quoteContainerLeft}`]: {
    position: "absolute",
    padding: 10,
    top: 0,
    zIndex: 1,
    maxWidth: 300,
    [theme.breakpoints.down("sm")]: {
      width: 260,
    },
    [theme.breakpoints.between("sm", "md")]: {
      top: 40,
    },
  },

  [`& .${classes.quoteContainerRight}`]: {
    position: "absolute",
    padding: 10,
    top: 0,
    zIndex: 1,
    maxWidth: 300,
    [theme.breakpoints.down("sm")]: {
      width: 260,
    },
    [theme.breakpoints.between("sm", "md")]: {
      top: 40,
    },
  },

  [`& .${classes.quoteText}`]: {
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "13px",
    },
  },

  [`& .${classes.quoteCompanyImage}`]: {
    height: "55px",
    width: "95px",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      height: "35px",
      width: "70px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "40px",
      width: "80px",
    },
  },

  [`& .${classes.quoteCompanyImageContainer}`]: {
    padding: "5px",
    background: "white",
    border: "1px solid #540096",
    borderRadius: "5px",
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      bottom: "90px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      bottom: "60px",
    },
  },
}));

const quotes = [
  {
    key: "ascot",
    timelapse: BusinessOne,
    //bubble: BubbleOne,
    //align: "left",
    quote: `"The integration of the UtterBerry chip technology within our exclusive swing badges (provided) us with a comprehensive understanding of attendee movement throughout our venue.
     UtterBerry's onboard artificial intelligence and machine learning capabilities offer predictive insights, enabling our management teams to proactively address potential crowd surges.
     For exclusive badges requiring identification, cameras linked with the UtterBerry system can locate the badges and associate them with individuals."`,
    author: `George R Vaughan, Ascot Racecourse`,
    speed: 2.3,
    image: Ascot,
  },
  {
    key: "bfk",
    timelapse: BusinessOne,
    //bubble: BubbleOne,
    //align: "left",
    quote: `“The sensors were used to monitor a series of brick arches that arches are among the oldest
    structures on the Underground network, but still carry several hundred trains a day. […] The UtterBerry system monitored the vibration and displacement of the arches as this took place, with simple installation and extremely high accuracy helping the works to go smoothly.”`,
    author: `Sujay Surji, BFK`,
    speed: 2.3,
    image: BFK,
  },
  // {
  //   key: "costain",
  //   timelapse: BusinessOne,
  //   bubble: BubbleTwo,
  //   align: "right",
  //   quote: `“We have worked with Utterberry at their early stage through its conception and research
  //   implementation at Cambridge University […] We see this as a transformative technology that is starting to change business models and providing a range of opportunities for Utterberry to provide valuable services at different entry points in infrastructure and other advanced markets.”`,
  //   author: `Tim Embley, Costain`,
  //   speed: 2.3,
  //   image: Costain,
  // },
  {
    key: "gammon",
    timelapse: BusinessOne,
    //bubble: BubbleTwo,
    //align: "right",
    quote: `“Access to real-time data provided much-needed reassurance to both engineers, the client and stakeholders that nearby sensitive receivers were unaffected by our works. Valuable improvements in health and safety throughout the trail period were also realised, with remote monitoring replacing the need to send engineers into the field to take readings. When compared with traditional deployment , costs can also be reduced with regard to both capital and resources.”`,
    author: `Andy Wong, Gammon`,
    speed: 2.3,
    image: Gammon,
  },
  {
    key: "swansea-uni",
    timelapse: BusinessOne,
    //bubble: BubbleOne,
    //align: "left",
    quote: `“Successful tests were constructed with the UtterBerry Sensor System since its installation in June 2019. This was the first installation of the sensor in a University in the UK and has been instrumental in providing a proactive approach to researchers returning to work in our laboratories. The data gathered has been extremely helpful for both UtterBerry and Swansea University, providing staff and students with an added level of safety and security with regards to COVID-19 detection.”`,
    author: `Professor Owen J Guy, Swansea University`,
    speed: 2.3,
    image: SwanseaUni,
  },
  {
    key: "catapult",
    timelapse: BusinessOne,
    //bubble: BubbleTwo,
    //align: "right",
    quote: `“In many cases the Covid detection measures have been human operated scanners. In my opinion the demand for a system like UtterBerry's COVID multi-symptom scanner sensor is huge and I very much look forward to working with Heba and the team to support their deployments of the sensors not only In the UK, but globally, and help showcase more cutting edge uk Innovation that can have huge social and economic impact around the world.”`,
    author: `Andrew Cockburn, Catapult`,
    speed: 2.3,
    image: Catapult,
  },
];

const QuoteBanner = React.forwardRef(
  ({ timelapse, bubble, align, speed, scale, quote, author, image }, ref) => {
    const { ref: inViewRef, inView } = useInView({
      /* Optional options */
      threshold: 0.6,
      triggerOnce: true,
    });

    const theme = useTheme();

    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const large = useMediaQuery(theme.breakpoints.up("xl"));

    const [showQuote, setShowQuote] = useState(false);

    const gImage = getImage(bubble);

    useEffect(() => {
      if (!inView) {
        setShowQuote(false);
      }
    }, [inView]);

    return (
      <div
        className={classes.banner}
        ref={inViewRef}
        style={{
          justifyContent:
            align === "left"
              ? mobile || large
                ? "flex-start"
                : "flex-end"
              : mobile || large
              ? "flex-end"
              : "flex-start",
        }}
      >
        <div className={classes.playerContainer}>
          <ReactPlayer
            url={timelapse}
            playing={inView}
            muted={true}
            playsinline={true}
            width={"100%"}
            height={scale ? 350 * scale : 350}
            playbackRate={speed ? speed : 1}
            onEnded={() => {
              setShowQuote(true);
            }}
          />
          <div
            className={classes.quoteCompanyImageContainer}
          >
            <img src={image} className={classes.quoteCompanyImage} alt={"Business"}/>
          </div>
          <Grow in={showQuote}>
            <div
              className={classes.quoteContainer}
              style={{
                right:
                  align === "left"
                    ? large
                      ? "-105%"
                      : mobile
                      ? "-135%"
                      : "-83%"
                    : "",
                left:
                  align === "right"
                    ? large
                      ? "-100%"
                      : mobile
                      ? "-130%"
                      : "-65%"
                    : "",
                top: mobile ? "10%" : 0,
              }}
            >
              <Typography
                style={{
                  maxWidth: 320,
                  color: "white",
                  zIndex: 1,
                }}
                className={classes.quoteText}
              >
                {quote}
              </Typography>
              <Typography
                style={{
                  maxWidth: 300,
                  color: "white",
                  zIndex: 1,
                  fontWeight: "700",
                }}
                className={classes.quoteText}
              >
                {author}
              </Typography>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                }}
              >
                <GatsbyImage
                  image={gImage}
                  className={
                    align === "right" ? classes.bubbleRight : classes.bubbleLeft
                  }
                  objectFit={"contain"}
                  alt={`Quote Bubble`}
                />
              </div>
            </div>
          </Grow>
        </div>
      </div>
    );
  }
);

const Quotes = (props) => {
  const theme = useTheme();

  const includeHexagons = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Root>
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <Typography variant={"h3"} className={classes.title}>
            Quotes About UtterBerry
          </Typography>

          <div className={classes.leftPointContainer}>
            <svg className={classes.point}>
              <circle
                cx='20'
                cy='21'
                r='15'
                stroke='#540096'
                strokeWidth='8'
                fill='none'
              />
            </svg>
          </div>
          <div className={classes.rightPointContainer}>
            <svg className={classes.point}>
              <circle
                cx='20'
                cy='21'
                r='15'
                stroke='#540096'
                strokeWidth='8'
                fill='none'
              />
            </svg>
          </div>
        </div>
        {quotes.map( (value, i) => {
          if(i%2===0){
            return (
            <div className={classes.bannerWrapper} key={value.key}>
              {includeHexagons && (
                <StaticImage
                  src={"../../images/utterberry-paints/multi-hex-1-removebg.webp"}
                  className={classes.floatingLeftHex}
                  objectFit={"contain"}
                  placeholder={"none"}
                  alt={"Floating Left Hex"}
                />
              )}
              <QuoteBanner
                timelapse={value.timelapse}
                bubble={props.images.bubbleOne}
                align={"left"}
                speed={value.speed}
                scale={value.scale}
                quote={value.quote}
                author={value.author}
                image={value.image}
              />
            </div>);
          }
          else{
            return (<div className={classes.bannerWrapper} key={value.key}>
              {includeHexagons && (
                <StaticImage
                  src={"../../images/utterberry-paints/multi-hex-2-removebg.webp"}
                  className={classes.floatingRightHex}
                  objectFit={"contain"}
                  placeholder={"none"}
                  alt={"Floating Right Hex"}
                />
              )}
              <QuoteBanner
                timelapse={value.timelapse}
                bubble={props.images.bubbleTwo}
                align={"right"}
                speed={value.speed}
                scale={value.scale}
                quote={value.quote}
                author={value.author}
                image={value.image}
              />
            </div>);
          }
        })}
      </div>
    </Root>
  );
};

export default Quotes;
