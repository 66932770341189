import { styled } from "@mui/material/styles";
import styledOri from "styled-components";

import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

import { Typography, Skeleton, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { a, useSpring, to } from "@react-spring/web";
import { Parallax } from "react-scroll-parallax";

import UtterBerryLogo from "../../images/utterberry-title-white.svg";
import TitleBanner from "../../images/about-images/title-banner-1.svg";

import BranchBackground from "../../images/branches/branch-background-3.svg";

import TextBoxBranch1 from "../../images/branches/textbox-branch-1.svg";
import TextBoxBranch2 from "../../images/branches/textbox-branch-2.svg";
import TextBoxBranch3 from "../../images/branches/textbox-branch-3.svg";
import TextBoxBranch4 from "../../images/branches/textbox-branch-4.svg";
import TextBoxBranch5 from "../../images/branches/textbox-branch-5.svg";
import TextBoxBranch6 from "../../images/branches/textbox-branch-6.svg";

import LinkOne from "../../images/branches/link-1.svg";
import LinkTwo from "../../images/branches/link-2.svg";
import LinkThree from "../../images/branches/link-3.svg";
import LinkFour from "../../images/branches/link-4.svg";
import LinkFive from "../../images/branches/link-5.svg";

const PREFIX = "FoldOutAbout";

const classes = {
  root: `${PREFIX}-root`,
  tileContainer: `${PREFIX}-tileContainer`,
  tile: `${PREFIX}-tile`,
  textTile: `${PREFIX}-textTile`,
  ratioDummy: `${PREFIX}-ratioDummy`,
  imageTile: `${PREFIX}-imageTile`,
  innerTile: `${PREFIX}-innerTile`,
  content: `${PREFIX}-content`,
  textContent: `${PREFIX}-textContent`,
  title: `${PREFIX}-title`,
  imageContent: `${PREFIX}-imageContent`,
  leftTileRow: `${PREFIX}-leftTileRow`,
  rightTileRow: `${PREFIX}-rightTileRow`,
  image: `${PREFIX}-image`,
  tileTextImage: `${PREFIX}-tileTextImage`,
  tileText: `${PREFIX}-tileText`,
  titleContainer: `${PREFIX}-titleContainer`,
  whiteTitle: `${PREFIX}-whiteTitle`,
  landingWrapper: `${PREFIX}-landingWrapper`,
  imageWrapper: `${PREFIX}-imageWrapper`,
  landingImage: `${PREFIX}-landingImage`,
  landingStroke: `${PREFIX}-landingStroke`,
  landingText: `${PREFIX}-landingText`,
  branchesContainer: `${PREFIX}-branchesContainer`,
  branches: `${PREFIX}-branches`,
  firstTitleWrapper: `${PREFIX}-firstTitleWrapper`,
  firstImageOne: `${PREFIX}-firstImageOne`,
  firstTitle: `${PREFIX}-firstTitle`,
  firstDescriptionOne: `${PREFIX}-firstDescriptionOne`,
  firstImageTwo: `${PREFIX}-firstImageTwo`,
  firstDescriptionTwo: `${PREFIX}-firstDescriptionTwo`,
  secondTitleWrapper: `${PREFIX}-secondTitleWrapper`,
  secondImageOne: `${PREFIX}-secondImageOne`,
  secondImageTwo: `${PREFIX}-secondImageTwo`,
  secondTitle: `${PREFIX}-secondTitle`,
  secondDescriptionOne: `${PREFIX}-secondDescriptionOne`,
  secondDescriptionTwo: `${PREFIX}-secondDescriptionTwo`,
  secondDescriptionWrapper: `${PREFIX}-secondDescriptionWrapper`,
  thirdTitleWrapper: `${PREFIX}-thirdTitleWrapper`,
  thirdImageOne: `${PREFIX}-thirdImageOne`,
  thirdTitle: `${PREFIX}-thirdTitle`,
  thirdImageTwo: `${PREFIX}-thirdImageTwo`,
  thirdDescriptionWrapper: `${PREFIX}-thirdDescriptionWrapper`,
  thirdDescriptionOne: `${PREFIX}-thirdDescriptionOne`,
  thirdDescriptionTwo: `${PREFIX}-thirdDescriptionTwo`,
  thirdDescriptionThree: `${PREFIX}-thirdDescriptionThree`,
  fourthWrapper: `${PREFIX}-fourthWrapper`,
  fourthTitleWrapper: `${PREFIX}-fourthTitleWrapper`,
  fourthTitle: `${PREFIX}-fourthTitle`,
  fourthImageContainer: `${PREFIX}-fourthImageContainer`,
  fourthImage: `${PREFIX}-fourthImage`,
  fourthTextWrapper: `${PREFIX}-fourthTextWrapper`,
  fourthImageOne: `${PREFIX}-fourthImageOne`,
  fourthImageTwo: `${PREFIX}-fourthImageTwo`,
  fourthDescriptionOne: `${PREFIX}-fourthDescriptionOne`,
  fourthDescriptionTwo: `${PREFIX}-fourthDescriptionTwo`,
  fifthDescriptionOne: `${PREFIX}-fifthDescriptionOne`,
  fifthDescriptionTwo: `${PREFIX}-fifthDescriptionTwo`,
  smImageContainer: `${PREFIX}-smImageContainer`,
  smImage: `${PREFIX}-smImage`,
  hexagonContainer: `${PREFIX}-hexagonContainer`,
  smallImageWrapperOne: `${PREFIX}-smallImageWrapperOne`,
  smallImageWrapperTwo: `${PREFIX}-smallImageWrapperTwo`,
  smallImageWrapperThree: `${PREFIX}-smallImageWrapperThree`,
  smallImageWrapperFour: `${PREFIX}-smallImageWrapperFour`,
  smallImageWrapperFive: `${PREFIX}-smallImageWrapperFive`,
};

const Root = styled("div")(({ theme }) => ({
  width: "100vw",
  zIndex: 1,
  position: "relative",
  overflow: "hidden",
  [`& .${classes.tileContainer}`]: {
    width: "100%",
    padding: "0px 20px 20px",
    zIndex: 1,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px 30px 10px",
    },
  },

  [`& .${classes.tile}`]: {
    maxWidth: 430,
    flexGrow: 1,
    minWidth: 400,
    minHeight: 400,
    position: "relative",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      minWidth: 290,
      minHeight: 350,
    },
  },

  [`& .${classes.textTile}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    padding: 10,
    [theme.breakpoints.up("md")]: {
      padding: 20,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0px 0px 10px",
    },
  },

  [`& .${classes.ratioDummy}`]: {
    marginTop: "100%",
  },

  [`& .${classes.imageTile}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    padding: 10,
    [theme.breakpoints.up("md")]: {
      padding: 20,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0px",
    },
  },

  [`& .${classes.innerTile}`]: {
    width: "100%",
    height: "100%",
    background: "#540096",
    borderRadius: 7,
    transformStyle: "preserve-3d",
    transform: "rotateX(0deg)",
    transformOrigin: "100% 0%",
    backfaceVisibility: "hidden",
    padding: 10,
    overflow: "hidden",
    boxShadow: "0px 0px 29px -2px rgba(0,0,0,0.44)",
    position: "relative",
    zIndex: 1,
  },

  [`& .${classes.content}`]: {
    backgroundColor: "white",
    height: "100%",
    width: "100%",
    borderRadius: 20,
    padding: 10,
    [theme.breakpoints.up("md")]: {
      padding: "8px 15px 15px 15px",
    },
  },

  [`& .${classes.textContent}`]: {
    background: "#540096",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  [`& .${classes.title}`]: {
    marginBottom: 30,
    paddingBottom: 10,
    borderBottom: "1px solid #540096",
  },

  [`& .${classes.imageContent}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    overflow: "hidden",
  },

  [`& .${classes.leftTileRow}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    zIndex: 0,
    "@media(max-width: 800px)": {
      justifyContent: "flex-start",
    },
  },

  [`& .${classes.rightTileRow}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    zIndex: 0,
    "@media(max-width: 800px)": {
      justifyContent: "flex-end",
    },
  },

  [`& .${classes.image}`]: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    borderRadius: 10,
  },

  [`& .${classes.tileTextImage}`]: {
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
    FontWeight: "400",
    fontSize: "0.75rem",
    lineHeight: "1.4",
    letterSpacing: "0.03em",
  },

  [`& .${classes.tileText}`]: {
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
    FontWeight: "400",
    fontSize: "0.9rem",
    lineHeight: "1.4",
    letterSpacing: "0.03em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      lineHeight: "1.2",
    },
  },

  // LANDING
  [`& .${classes.titleContainer}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },

  [`& .${classes.whiteTitle}`]: {
    color: "#fff",
    paddingBottom: 5,
    fontWeight: "600",
    fontSize: 53,
    [theme.breakpoints.up("lg")]: {
      fontSize: 68,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 38,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
  },

  [`& .${classes.landingWrapper}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    position: "relative",
  },

  [`& .${classes.imageWrapper}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    alignItems: "center",
    padding: "40px",
    margin: "0px 0px 50px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0px 20px 0px",
    },
  },

  [`& .${classes.landingImage}`]: {
    position: "relative",
    zIndex: 1,
    marginLeft: "18px",
    height: "55px",
    paddingBottom: "1px",
    [theme.breakpoints.up("lg")]: {
      height: "68px",
    },
    [theme.breakpoints.down("md")]: {
      height: "40px",
      marginLeft: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "32px",
      marginLeft: "9px",
    },
  },

  [`& .${classes.landingStroke}`]: {
    width: "100%",
    position: "relative",
    top: 0,
    left: 0,
    zIndex: 0,
    objectFit: "fill",
    [theme.breakpoints.down("sm")]: {
      minHeight: "150px",
      width: "600px",
    },
  },

  [`& .${classes.landingText}`]: {
    maxWidth: 820,
    zIndex: 2,
    margin: "20px 0px",
    textAlign: "center",
    fontSize: "1.1rem",
    padding: "0px 20px",
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.9rem",
      margin: "0px 0px 20px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      margin: "0px 0px 20px 0px",
    },
  },

  [`& .${classes.branchesContainer}`]: {
    width: "100%",
    height: 40,
    position: "relative",
  },

  [`& .${classes.branches}`]: {
    backgroundImage: `url("${BranchBackground}")`,
    width: "100%",
    backgroundSize: "285px 100px",
    backgroundRepeat: "repeat-x",
    backgroundPosition: "top",
    height: 100,
    borderTop: "solid 3px #540096",
    position: "absolute",
    left: 0,
    top: 0,
  },

  // FIRST BOX
  [`& .${classes.firstTitleWrapper}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 15,
    },
  },

  [`& .${classes.firstImageOne}`]: {
    width: "100%",
    position: "absolute",
    left: 18,
    top: -22,
    [theme.breakpoints.down("sm")]: {
      top: -10,
    },
  },

  [`& .${classes.firstTitle}`]: {
    color: "white",
    marginTop: 5,
    marginBottom: 18,
  },

  [`& .${classes.firstDescriptionOne}`]: {
    padding: "4px 15px 0px 15px",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.firstImageTwo}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 15,
    left: 0,
    [theme.breakpoints.down("sm")]: {
      top: 25,
    },
  },

  [`& .${classes.firstDescriptionTwo}`]: {
    paddingLeft: "30%",
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    flex: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  // SECOND BOX
  [`& .${classes.secondTitleWrapper}`]: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "relative",
    paddingLeft: 50,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
    },
  },

  [`& .${classes.secondImageOne}`]: {
    width: "100%",
    position: "absolute",
    left: 0,
    top: 30,
    zIndex: 1,
    transform: "rotate(90deg)",
    [theme.breakpoints.down("sm")]: {
      top: 41,
    },
  },

  [`& .${classes.secondImageTwo}`]: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: -5,
    [theme.breakpoints.down("sm")]: {
      top: 5,
    },
  },

  [`& .${classes.secondTitle}`]: {
    color: "white",
    marginTop: 10,
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
  },

  [`& .${classes.secondDescriptionOne}`]: {
    padding: "10px",
    flex: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "4px 10px",
    },
  },

  [`& .${classes.secondDescriptionTwo}`]: {
    padding: 10,
    flex: 5,
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },

  [`& .${classes.secondDescriptionWrapper}`]: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "stretch",
    flexDirection: "column",
  },

  // THIRD BOX
  [`& .${classes.thirdTitleWrapper}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    paddingLeft: 20,
  },

  [`& .${classes.thirdImageOne}`]: {
    width: "100%",
    position: "absolute",
    left: 40,
    top: -20,
    zIndex: 1,
    transform: "rotate(-90deg) translateY(25%)",
    [theme.breakpoints.down("sm")]: {
      top: 20,
      left: 30,
    },
  },

  [`& .${classes.thirdTitle}`]: {
    color: "white",
    marginTop: 5,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 15,
    },
  },

  [`& .${classes.thirdImageTwo}`]: {
    width: "100%",
    position: "absolute",
    top: -10,
    left: 20,
    transform: "scaleX(-1)",
    [theme.breakpoints.down("sm")]: {
      top: -1,
    },
  },

  [`& .${classes.thirdDescriptionWrapper}`]: {
    flex: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "stretch",
  },

  [`& .${classes.thirdDescriptionOne}`]: {
    padding: "20px 10px 10px",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "30px 10px 10px",
    },
  },

  [`& .${classes.thirdDescriptionTwo}`]: {
    padding: "10px 10px 0px 10px",
    flex: 2,
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },

  [`& .${classes.thirdDescriptionThree}`]: {
    padding: "5px 10px 15px 10px",
    flex: 1,
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },

  // FOURTH BOX
  [`& .${classes.fourthWrapper}`]: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    zIndex: 0,
  },

  [`& .${classes.fourthTitleWrapper}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    paddingLeft: 80,
  },

  [`& .${classes.fourthTitle}`]: {
    color: "white",
    marginTop: 10,
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },

  [`& .${classes.fourthImageContainer}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },

  [`& .${classes.fourthImage}`]: {
    width: "100%",
    position: "absolute",
    left: 0,
    top: -5,
    zIndex: 1,
  },

  [`& .${classes.fourthTextWrapper}`]: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "stretch",
    flexDirection: "row",
  },

  [`& .${classes.fourthImageOne}`]: {
    width: "100%",
    position: "absolute",
    left: 0,
    top: -5,
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      top: 0,
    },
  },

  [`& .${classes.fourthImageTwo}`]: {
    width: "100%",
    position: "absolute",
    top: 70,
    left: 0,
    [theme.breakpoints.down("sm")]: {
      top: 90,
    },
  },

  [`& .${classes.fourthDescriptionOne}`]: {
    padding: "20px 10px 5px 30px",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    zIndex: 1,
  },

  [`& .${classes.fourthDescriptionTwo}`]: {
    paddingRight: 10,
    paddingTop: 15,
    flex: 1,
    zIndex: 1,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  // FIFTH TEXT BOX
  [`& .${classes.fifthDescriptionOne}`]: {
    padding: "8px 15px 0px 15px",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.fifthDescriptionTwo}`]: {
    paddingLeft: "30%",
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    flex: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  // SM BREAKPOINT IMAGE
  [`& .${classes.smallImageWrapperOne}`]: {
    position: "relative",
    width: 250,
    height: "75%",
    right: 0,
    [theme.breakpoints.down("sm")]: {
      width: 200,
      height: "60%",
      right: 15,
    },
    "@media(max-width: 425px)": {
      right: 35,
    },
  },
  [`& .${classes.smallImageWrapperTwo}`]: {
    position: "relative",
    width: 200,
    height: "80%",
    left: 0,
    [theme.breakpoints.down("sm")]: {
      width: 180,
      height: "90%",
      left: 15,
    },
    "@media(max-width: 425px)": {
      left: 55,
    },
  },
  [`& .${classes.smallImageWrapperThree}`]: {
    position: "relative",
    width: 250,
    height: "75%",
    right: 0,
    [theme.breakpoints.down("sm")]: {
      width: 200,
      height: "85%",
    },
    "@media(max-width: 425px)": {
      right: 35,
    },
  },
  [`& .${classes.smallImageWrapperFour}`]: {
    position: "relative",
    width: 240,
    height: "100%",
    left: 0,
    [theme.breakpoints.down("sm")]: {
      width: 200,
      height: "60%",
      left: 30,
    },
    "@media(max-width: 425px)": {
      left: 85,
    },
  },
  [`& .${classes.smallImageWrapperFive}`]: {
    position: "relative",
    width: 250,
    height: "80%",
    right: 0,
    [theme.breakpoints.down("sm")]: {
      width: 200,
      height: "85%",
      right: 20,
    },
    "@media(max-width: 425px)": {
      right: 55,
    },
  },

  [`& .${classes.smImageContainer}`]: {
    height: "300px",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: -1,
  },

  [`& .${classes.smImage}`]: {
    height: "270px",
    position: "absolute",
    left: 0,
    top: 0,
    // transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
      transform: "translateY(25%)",
    },
  },

  // HEXAGON FILLS
  [`& .${classes.hexagonContainer}`]: {
    position: "absolute",
    top: "50%",
    width: 250,
    zIndex: -2,
  },
}));

const Tile = React.forwardRef((props, ref) => {
  return (
    <div
      className={classes.tile}
      ref={ref}
      style={{
        zIndex: props.zIndex || 0,
      }}
    >
      <div className={classes.ratioDummy}>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            padding: "25px",
            overflow: "hidden",
          }}
        >
          <Skeleton width={"100%"} height={"100%"} variant={"rectangular"} />
        </div>
      </div>
      <div className={classes.textTile}>{props.children}</div>
    </div>
  );
});

const TilePlacerContainer = styledOri.div`

  max-width: 430px;
  flex-grow: 1;
  min-width: 400px;
  min-height: 400px;
  position: relative;
  z-index: 1;

  @media(max-width: 600px) {
    minWidth: 290px;
    minHeight: 350px;
  }

  @media(max-width:800px) {
    display: none;
  }
`;

const SmallImageContainer = styledOri.div`

  height: 300px;
  flex-grow: 1,
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;

  @media(min-width:799.99px) {
    display: none;
  }
`;

const TilePlacer = React.forwardRef((props, ref) => {
  return (
    <TilePlacerContainer
      ref={ref}
      style={{
        zIndex: props.zIndex || 0,
      }}
    >
      <div className={classes.ratioDummy}>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            padding: "25px",
            overflow: "hidden",
          }}
        >
          <Skeleton width={"100%"} height={"100%"} variant={"rectangular"} />
        </div>
      </div>
      <div className={classes.textTile}>{props.children}</div>
    </TilePlacerContainer>
  );
});

const SwingDown = (props) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [visible, setVisible] = useState(false);
  const [linkVisible, setLinkVisible] = useState(false);

  const {
    y,
    x,
    opacity: connectorOpacity,
  } = useSpring({
    y: linkVisible ? 0 : -60,
    x: 0,
    opacity: linkVisible ? 1 : 0,
  });

  const { transform, opacity } = useSpring({
    opacity: visible ? 1 : 0,
    transform: `perspective(600px) rotateX(${visible ? 0 : -180}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
    onRest: () => {
      setLinkVisible(true);
    },
    onStart: () => {
      setLinkVisible(false);
    },
  });

  useEffect(() => {
    if (inView) {
      if (props.delay) {
        setTimeout(() => {
          setVisible(true);
        }, props.delay);
      } else {
        setVisible(true);
      }
    }
  }, [inView, props.delay]);

  return (
    <TilePlacer ref={ref} zIndex={props.zIndex}>
      <a.div
        style={{
          height: "100%",
          width: "100%",
          opacity: opacity.to((o) => o),
          transform,
          transformOrigin: "50% 0%",
          backfaceVisibility: "hidden",
          zIndex: 1,
          position: "relative",
        }}
      >
        {props.children}
      </a.div>
      {props.connectorStyles && (
        <div style={props.connectorStyles}>
          <a.div
            style={{
              transform: to([x, y], (x, y) => `translate(${x}px, ${y}px)`),
              opacity: connectorOpacity.to((o) => o),
            }}
          >
            {props.connectorComponent}
          </a.div>
        </div>
      )}
    </TilePlacer>
  );
};

const SwingRight = (props) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [visible, setVisible] = useState(false);
  const [linkVisible, setLinkVisible] = useState(false);

  const {
    y,
    x,
    opacity: connectorOpacity,
  } = useSpring({
    y: 0,
    x: linkVisible ? 0 : -60,
    opacity: linkVisible ? 1 : 0,
  });

  const { transform, opacity } = useSpring({
    opacity: visible ? 1 : 0,
    transform: `perspective(600px) rotateY(${visible ? 0 : 180}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
    onRest: () => {
      setLinkVisible(true);
    },
    onStart: () => {
      setLinkVisible(false);
    },
  });

  useEffect(() => {
    if (inView) {
      if (props.delay) {
        setTimeout(() => {
          setVisible(true);
        }, props.delay);
      } else {
        setVisible(true);
      }
    }
  }, [inView, props.delay]);

  return (
    <Tile ref={ref} zIndex={props.zIndex}>
      <a.div
        style={{
          height: "100%",
          width: "100%",
          opacity: opacity.to((o) => o),
          transform,
          transformOrigin: "0% 50%",
          backfaceVisibility: "hidden",
          zIndex: 1,
          position: "relative",
        }}
      >
        {props.children}
      </a.div>
      {props.connectorStyles && (
        <div style={props.connectorStyles}>
          <a.div
            style={{
              transform: to([x, y], (x, y) => `translate(${x}px, ${y}px)`),
              opacity: connectorOpacity.to((o) => o),
            }}
          >
            {props.connectorComponent}
          </a.div>
        </div>
      )}
    </Tile>
  );
};

const SwingLeft = (props) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [visible, setVisible] = useState(false);
  const [linkVisible, setLinkVisible] = useState(false);

  const {
    y,
    x,
    opacity: connectorOpacity,
  } = useSpring({
    y: 0,
    x: linkVisible ? 0 : 60,
    opacity: linkVisible ? 1 : 0,
  });

  const { transform, opacity } = useSpring({
    opacity: visible ? 1 : 0,
    transform: `perspective(600px) rotateY(${visible ? 0 : -180}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
    onRest: () => {
      setLinkVisible(true);
    },
    onStart: () => {
      setLinkVisible(false);
    },
  });

  useEffect(() => {
    if (inView) {
      if (props.delay) {
        setTimeout(() => {
          setVisible(true);
        }, props.delay);
      } else {
        setVisible(true);
      }
    }
  }, [inView, props.delay]);

  return (
    <Tile ref={ref}>
      <a.div
        style={{
          height: "100%",
          width: "100%",
          opacity: opacity.to((o) => o),
          transform,
          transformOrigin: "100% 50%",
          backfaceVisibility: "hidden",
          zIndex: 1,
          position: "relative",
        }}
      >
        {props.children}
      </a.div>
      {props.connectorStyles && (
        <div style={props.connectorStyles}>
          <a.div
            style={{
              transform: to([x, y], (x, y) => `translate(${x}px, ${y}px)`),
              opacity: connectorOpacity.to((o) => o),
            }}
          >
            {props.connectorComponent}
          </a.div>
        </div>
      )}
    </Tile>
  );
};

const FirstTextBox = ({ title, descriptionOne, descriptionTwo }) => {
  return (
    <div
      className={classes.innerTile}
      style={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className={classes.firstTitleWrapper}>
        <img
          src={TextBoxBranch2}
          className={classes.firstImageOne}
          alt={"Text Box Track Branches 1"}
        />
        <Typography variant={"h5"} className={classes.firstTitle}>
          {title}
        </Typography>
      </div>
      <div className={classes.firstDescriptionOne}>
        <Typography
          style={{
            color: "white",
          }}
          className={classes.tileText}
        >
          {descriptionOne}
        </Typography>
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        <img
          src={TextBoxBranch1}
          className={classes.firstImageTwo}
          alt={"Text Box Track Branches 2"}
        />
      </div>
      <div className={classes.firstDescriptionTwo}>
        <Typography
          style={{
            color: "white",
          }}
          className={classes.tileText}
        >
          {descriptionTwo}
        </Typography>
      </div>
    </div>
  );
};

const SecondTextBox = ({ title, descriptionOne, descriptionTwo }) => {
  return (
    <div
      className={classes.innerTile}
      style={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
        zIndex: 0,
      }}
    >
      <div className={classes.secondTitleWrapper}>
        <img
          src={TextBoxBranch3}
          className={classes.secondImageOne}
          alt={"Text Box Track Branches 3"}
        />
        <Typography variant={"h5"} className={classes.secondTitle}>
          {title}
        </Typography>
      </div>
      <div className={classes.secondDescriptionWrapper}>
        <div className={classes.secondDescriptionOne}>
          <Typography
            style={{
              color: "white",
            }}
            className={classes.tileText}
          >
            {descriptionOne}
          </Typography>
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <img
            src={TextBoxBranch4}
            className={classes.secondImageTwo}
            alt={"Text Box Track Branches 4"}
          />
        </div>
        <div className={classes.secondDescriptionTwo}>
          <Typography
            style={{
              color: "white",
            }}
            className={classes.tileText}
          >
            {descriptionTwo}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const ThirdTextBox = ({
  title,
  descriptionOne,
  descriptionTwo,
  descriptionThree,
}) => {
  return (
    <div
      className={classes.innerTile}
      style={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
        zIndex: 0,
      }}
    >
      <div className={classes.thirdTitleWrapper}>
        <img
          src={TextBoxBranch3}
          className={classes.thirdImageOne}
          alt={"Text Box Track Branches 5"}
        />
        <Typography variant={"h5"} className={classes.thirdTitle}>
          {title}
        </Typography>
      </div>
      <div
        style={{
          width: "100%",
          flexGrow: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "stretch",
          flexDirection: "row",
        }}
      >
        <div>
          <img
            src={TextBoxBranch4}
            className={classes.thirdImageTwo}
            alt={"Text Box Track Branches 10"}
          />
        </div>
        <div className={classes.thirdDescriptionWrapper}>
          <div className={classes.thirdDescriptionOne}>
            <Typography
              style={{
                color: "white",
              }}
              className={classes.tileText}
            >
              {descriptionOne}
            </Typography>
          </div>
          <div className={classes.thirdDescriptionTwo}>
            <Typography
              style={{
                color: "white",
              }}
              className={classes.tileText}
            >
              {descriptionTwo}
            </Typography>
          </div>
          <div className={classes.thirdDescriptionThree}>
            <Typography
              style={{
                color: "white",
              }}
              className={classes.tileText}
            >
              {descriptionThree}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const FourthTextBox = ({ title, descriptionOne, descriptionTwo }) => {
  return (
    <div className={`${classes.innerTile} ${classes.fourthWrapper}`}>
      <div className={classes.fourthTitleWrapper}>
        <img
          src={TextBoxBranch5}
          className={classes.fourthImageOne}
          alt={"Text Box Track Branches 5"}
        />
        <Typography variant={"h5"} className={classes.fourthTitle}>
          {title}
        </Typography>
      </div>
      <div
        style={{
          width: "100%",
          flexGrow: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "stretch",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <img
            src={TextBoxBranch6}
            className={classes.fourthImageTwo}
            alt={"Text Box Track Branches 7"}
          />
        </div>
        <div
          style={{
            flex: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "stretch",
          }}
        >
          <div className={classes.fourthDescriptionOne}>
            <Typography
              style={{
                color: "white",
              }}
              className={classes.tileText}
            >
              {descriptionOne}
            </Typography>
          </div>
          <div className={classes.fourthDescriptionTwo}>
            <Typography
              style={{
                color: "white",
              }}
              className={classes.tileText}
            >
              {descriptionTwo}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const FifthTextBox = ({ title, descriptionOne, descriptionTwo }) => {
  return (
    <div
      className={classes.innerTile}
      style={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className={classes.firstTitleWrapper}>
        <img
          src={TextBoxBranch2}
          className={classes.firstImageOne}
          alt={"Text Box Track Branches 7"}
        />
        <Typography variant={"h5"} className={classes.firstTitle}>
          {title}
        </Typography>
      </div>
      <div className={classes.fifthDescriptionOne}>
        <Typography
          style={{
            color: "white",
          }}
          className={classes.tileText}
        >
          {descriptionOne}
        </Typography>
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        <img
          src={TextBoxBranch1}
          className={classes.firstImageTwo}
          alt={"Text Box Track Branches 8"}
        />
      </div>
      <div className={classes.fifthDescriptionTwo}>
        <Typography
          style={{
            color: "white",
          }}
          className={classes.tileText}
        >
          {descriptionTwo}
        </Typography>
      </div>
    </div>
  );
};

const TileImageCard = ({ imageSrc, extraDescription, style, alt }) => {
  const gImage = getImage(imageSrc);

  return (
    <div className={classes.innerTile}>
      <div className={`${classes.content} ${classes.imageContent}`}>
        <GatsbyImage
          image={gImage}
          className={classes.image}
          style={{
            ...style,
          }}
          objectFit={"contain"}
          alt={`${alt} - Image`}
          width={"340px"}
          height={"350px"}
        />
        {!!extraDescription && (
          <Typography
            style={{
              color: "#540096",
              textAlign: "center",
            }}
            className={classes.tileTextImage}
          >
            {extraDescription}
          </Typography>
        )}
      </div>
    </div>
  );
};

const FoldOutAbout = (props) => {
  const includeHexagonFills = useMediaQuery("(min-width: 900px)");

  return (
    <Root>
      {/* LANDING SECTION */}
      <div
        style={{
          width: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={TitleBanner}
          className={classes.landingStroke}
          alt={"Title Banner"}
        />
        <div
          style={{
            zIndex: 2,
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -70%)",
          }}
        >
          <span className={classes.titleContainer}>
            <Typography variant={"h2"} className={classes.whiteTitle}>
              About
            </Typography>
            <img
              src={UtterBerryLogo}
              className={classes.landingImage}
              alt={"UtterBerry"}
            />
          </span>
        </div>
      </div>
      <div className={classes.landingWrapper}>
        <Typography className={classes.landingText}>
          UtterBerry is a UK based technology and R&D company and the provider
          of the UtterBerry smart sensor system. Focused on creating novel and
          innovative technology to confront the future and develop smarter, more
          informed and more reactive environments. Whether it be security and
          crowd monitoring at a sporting venue or sub-millimetre infrastructure
          sensing on large scale construction sites, UtterBerry’s innovative
          technology can create a smarter world.
        </Typography>
      </div>
      {/* TILE CONTAINER */}
      <div className={classes.tileContainer}>
        <div
          className={classes.leftTileRow}
          style={{
            zIndex: 4,
          }}
        >
          {
            <SwingDown zIndex={5} direction={"up"}>
              <TileImageCard
                imageSrc={props.images.imageOne}
                alt={"UtterBerry Sensors"}
              />
            </SwingDown>
          }
          <SwingRight
            delay={400}
            zIndex={4}
            connectorComponent={
              <img
                src={LinkOne}
                style={{
                  width: 60,
                }}
                alt={""}
              />
            }
            connectorStyles={{
              position: "absolute",
              width: 60,
              top: "50%",
              left: -30,
              zIndex: 0,
              transform: "translate(0%, -50%)",
            }}
          >
            <FirstTextBox
              title={"The UtterBerry Sensor"}
              descriptionOne={`The UtterBerry Sensor System leads our innovative product line. Made up of a collection of sensors, no bigger than a matchbox, placed throughout a project site the system of sensors communicate amongst themselves with no need for line of sight.`}
              descriptionTwo={`The system relays all of the data collected to a basestation which in turn relays the data to our utterberry.io portal through our patented UtterBerry blockchain for secure and safe data collection and transfer.`}
            />
          </SwingRight>
          {
            <SmallImageContainer>
              <div className={classes.smallImageWrapperOne}>
                <StaticImage
                  src={"../../images/about-images/sensor-3.webp"}
                  className={classes.smImage}
                  objectFit={"contain"}
                  style={{
                    transform: "scale(1.5)",
                    transformOrigin: "center",
                  }}
                  placeholder={"blurred"}
                  alt={"UtterBerry Sensors"}
                />
              </div>
            </SmallImageContainer>
          }
          {includeHexagonFills && (
            <Parallax
              y={["-50px", "50px"]}
              className={classes.hexagonContainer}
              styleOuter={{
                right: 0,
              }}
            >
              <StaticImage
                src={"../../images/utterberry-paints/hex-4.webp"}
                style={{
                  position: "absolute",
                  right: 0,
                  width: "100%",
                  transform: `translate(200px, -50%) rotate(-45deg)`,
                }}
                objectFit={"contain"}
                placeholder={"none"}
                alt={"Hex Right 1"}
              />
            </Parallax>
          )}
        </div>
        <div
          className={classes.rightTileRow}
          style={{
            zIndex: 3,
          }}
        >
          {
            <SmallImageContainer>
              <div className={classes.smallImageWrapperTwo}>
                <StaticImage
                  src={"../../images/about-images/ai-3.webp"}
                  className={classes.smImage}
                  objectFit={"contain"}
                  placeholder={"blurred"}
                  alt={"Artificial Intelligence & Machine Learning"}
                />
              </div>
            </SmallImageContainer>
          }
          <SwingLeft
            delay={400}
            zIndex={3}
            connectorComponent={
              <img
                src={LinkThree}
                style={{
                  width: 60,
                }}
                alt={""}
              />
            }
            connectorStyles={{
              position: "absolute",
              width: 60,
              top: "50%",
              right: -30,
              zIndex: 0,
              transform: "translate(0%, -50%)",
            }}
          >
            <SecondTextBox
              title={"AI & Machine Learning"}
              descriptionOne={`The UtterBerry Sensor has onboard artificial intelligence and machine learning, allowing them to not only communicate with each other with ease, but also learn as each project advances. `}
              descriptionTwo={`The AI and ML work together to generate an understanding of the environment that is unparalleled in other sensing solutions and forms the backbone of the UtterBerry sensors unique abilities and unrivalled sensing capabilities.`}
            />
          </SwingLeft>
          {
            <SwingDown
              delay={0}
              zIndex={2}
              connectorComponent={
                <img
                  src={LinkTwo}
                  style={{
                    width: 90,
                  }}
                  alt={""}
                />
              }
              connectorStyles={{
                position: "absolute",
                top: "0%",
                left: "50%",
                zIndex: 0,
                transform: "translate(-50%, -50%)",
              }}
            >
              <TileImageCard
                imageSrc={props.images.imageTwo}
                alt={"Artificial Intelligence & Machine Learning"}
                style={{
                  height: "85%",
                }}
              />
            </SwingDown>
          }
          {includeHexagonFills && (
            <Parallax
              y={["-50px", "50px"]}
              className={classes.hexagonContainer}
              styleOuter={{
                left: 0,
              }}
            >
              <StaticImage
                src={"../../images/utterberry-paints/hex-5.webp"}
                style={{
                  position: "absolute",
                  left: 0,
                  width: "100%",
                  transform: `translate(-200px, -50%) rotate(45deg)`,
                }}
                objectFit={"contain"}
                placeholder={"none"}
                alt={"Hex Left 1"}
              />
            </Parallax>
          )}
        </div>
        <div
          className={classes.leftTileRow}
          style={{
            zIndex: 2,
          }}
        >
          {
            <SwingDown
              zIndex={1}
              connectorComponent={
                <img
                  src={LinkFour}
                  style={{
                    width: 90,
                  }}
                  alt={""}
                />
              }
              connectorStyles={{
                position: "absolute",
                width: 90,
                top: "0%",
                left: "50%",
                zIndex: 0,
                transform: "translate(-50%, -50%)",
              }}
            >
              <TileImageCard
                imageSrc={props.images.imageThree}
                alt={"UtterBerry.io"}
                style={{
                  height: "90%",
                }}
              />
            </SwingDown>
          }
          <SwingRight
            delay={400}
            connectorComponent={
              <img
                src={LinkFive}
                style={{
                  width: 60,
                }}
                alt={""}
              />
            }
            connectorStyles={{
              position: "absolute",
              width: 60,
              top: "50%",
              left: -30,
              zIndex: 0,
              transform: "translate(0%, -50%)",
            }}
          >
            <ThirdTextBox
              title={"UtterBerry.io"}
              descriptionOne={`The utterberry.io portal allows the data that the sensor system collects to be viewed, interacted with and independently active.`}
              descriptionTwo={`Utilising custom 3D models that allow users to more accurate envision the measurements the sensors are receiving we can provide accurate visual information reflecting the situation on site.`}
              descriptionThree={`This same portal can be used to set up alerts for relevant personnel, if any unexpected issues arise an alert can be sent instantly to all those who require the information, allowing for time and money to be saved.`}
            />
          </SwingRight>
          {
            <SmallImageContainer>
              <div className={classes.smallImageWrapperThree}>
                <StaticImage
                  src={"../../images/about-images/io.webp"}
                  className={classes.smImage}
                  objectFit={"contain"}
                  placeholder={"blurred"}
                  alt={"UtterBerry.io"}
                />
              </div>
            </SmallImageContainer>
          }
          {includeHexagonFills && (
            <Parallax
              y={["-50px", "50px"]}
              className={classes.hexagonContainer}
              styleOuter={{
                right: 0,
              }}
            >
              <StaticImage
                src={"../../images/utterberry-paints/hex-4.webp"}
                style={{
                  position: "absolute",
                  right: 0,
                  width: "100%",
                  transform: `translate(200px, -50%) rotate(-45deg)`,
                }}
                objectFit={"contain"}
                placeholder={"none"}
                alt={"Hex Right 2"}
              />
            </Parallax>
          )}
        </div>
        <div
          className={classes.rightTileRow}
          style={{
            zIndex: 1,
          }}
        >
          {
            <SmallImageContainer>
              <div className={classes.smallImageWrapperFour}>
                <StaticImage
                  src={"../../images/about-images/london-working.webp"}
                  className={classes.smImage}
                  style={{
                    transform: "scale(1.5)",
                    transformOrigin: "center",
                  }}
                  objectFit={"contain"}
                  placeholder={"blurred"}
                  alt={"London Working"}
                />
              </div>
            </SmallImageContainer>
          }
          <SwingLeft
            delay={400}
            zIndex={3}
            connectorComponent={
              <img
                src={LinkThree}
                style={{
                  width: 60,
                }}
                alt={""}
              />
            }
            connectorStyles={{
              position: "absolute",
              width: 60,
              top: "50%",
              right: -30,
              zIndex: 0,
              transform: "translate(0%, -50%)",
            }}
          >
            <FourthTextBox
              title={"The Company"}
              descriptionOne={`The company is based in London where it designs and develops its own hardware and software technologies, along with a new development in Leeds, housing our Manufacturing & Innovation hub.`}
              descriptionTwo={`Its Smart sensors are all manufactured within the UK and exported throughout the world for a large range of projects.`}
            />
          </SwingLeft>
          {
            <SwingDown
              delay={0}
              zIndex={2}
              connectorComponent={
                <img
                  src={LinkTwo}
                  style={{
                    width: 90,
                  }}
                  alt={""}
                />
              }
              connectorStyles={{
                position: "absolute",
                top: "0%",
                left: "50%",
                zIndex: 0,
                transform: "translate(-50%, -50%)",
              }}
            >
              <TileImageCard
                imageSrc={props.images.imageFour}
                alt={"London Working"}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </SwingDown>
          }
          {includeHexagonFills && (
            <Parallax
              y={["-50px", "50px"]}
              className={classes.hexagonContainer}
              styleOuter={{
                left: 0,
              }}
            >
              <StaticImage
                src={"../../images/utterberry-paints/hex-6.webp"}
                style={{
                  position: "absolute",
                  left: 0,
                  width: "100%",
                  transform: `translate(-200px, -50%) rotate(45deg)`,
                }}
                objectFit={"contain"}
                placeholder={"none"}
                alt={"Hex Left 2"}
              />
            </Parallax>
          )}
        </div>
        <div
          className={classes.leftTileRow}
          style={{
            zIndex: 0,
          }}
        >
          {
            <SwingDown
              zIndex={1}
              direction={"up"}
              connectorComponent={
                <img
                  src={LinkTwo}
                  style={{
                    width: 90,
                  }}
                  alt={""}
                />
              }
              connectorStyles={{
                position: "absolute",
                width: 90,
                top: "0%",
                left: "50%",
                zIndex: 0,
                transform: "translate(-50%, -50%)",
              }}
            >
              <TileImageCard
                imageSrc={props.images.imageFive}
                alt={"Heba on a Podium"}
                style={{
                  height: "85%",
                }}
              />
            </SwingDown>
          }
          <SwingRight
            delay={400}
            zIndex={0}
            connectorComponent={
              <img
                src={LinkOne}
                style={{
                  width: 60,
                }}
                alt={""}
              />
            }
            connectorStyles={{
              position: "absolute",
              width: 60,
              top: "50%",
              left: -30,
              zIndex: 0,
              transform: "translate(0%, -50%)",
            }}
          >
            <FifthTextBox
              title={"Founder & CEO"}
              descriptionOne={`UtterBerry was founded in 2013 by Heba Bevan as a spin-out from Cambridge University. Heba attended The University of Cambridge to do her PhD in low power wireless sensor networks, it was during her PhD that Heba founded UtterBerry.`}
              descriptionTwo={`Today, Heba now has multiple patents to her name in a wide range of fields from manufacturing and engineering to medicine and sensing.`}
            />
          </SwingRight>
          {
            <SmallImageContainer>
              <div className={classes.smallImageWrapperFive}>
                <StaticImage
                  src={"../../images/about-images/heba-podium.webp"}
                  className={classes.smImage}
                  objectFit={"contain"}
                  placeholder={"blurred"}
                  alt={"Heba On Podium"}
                />
              </div>
            </SmallImageContainer>
          }
          {includeHexagonFills && (
            <Parallax
              y={["-50px", "50px"]}
              className={classes.hexagonContainer}
              styleOuter={{
                right: 0,
              }}
            >
              <StaticImage
                src={"../../images/utterberry-paints/hex-4.webp"}
                style={{
                  position: "absolute",
                  right: 0,
                  width: "100%",
                  transform: `translate(200px, -50%) rotate(-45deg)`,
                }}
                objectFit={"contain"}
                placeholder={"none"}
                alt={"Hex Right 3"}
              />
            </Parallax>
          )}
        </div>
      </div>
    </Root>
  );
};

export default FoldOutAbout;
