import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography, Grow } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { EmojiEventsSharp, Star } from "@mui/icons-material";

const PREFIX = "HexagonEvent";

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  hexagonContainer: `${PREFIX}-hexagonContainer`,
  hexagonContent: `${PREFIX}-hexagonContent`,
  descriptionContainer: `${PREFIX}-descriptionContainer`,
  description: `${PREFIX}-description`,
  rope: `${PREFIX}-rope`,
  startingRope: `${PREFIX}-startingRope`,
  hexagonWrapper: `${PREFIX}-hexagonWrapper`,
  timestamp: `${PREFIX}-timestamp`,
  timestampContainer: `${PREFIX}-timestampContainer`,
  awardImage: `${PREFIX}-awardImage`,
  awardStarImage: `${PREFIX}-awardStarImage`,
};

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  minWidth: 150,
  maxWidth: "33%",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "50%",
  },

  [`& .${classes.image}`]: {
    width: 100,
    height: 117,
    objectFit: "contain",
    clipPath: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
    transform: "translate(0%, -28.4%)",
    background: "white",
    padding: "15px 5px",
  },

  [`& .${classes.descriptionContainer}`]: {
    padding: 10,
    borderRadius: 5,
    boxShadow: "0 0 10px #874db6",
    width: "80%",
    maxWidth: 320,
    position: "relative",
    zIndex: 0,
    background: "#540096",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: 5,
    },
  },

  [`& .${classes.description}`]: {
    textAlign: "start",
    fontSize: 14,
    zIndex: 1,
    position: "relative",
    color: "white",
    paddingLeft: "40px",
    [theme.breakpoints.down("md")]: {
      fontSize: 11,
    },
  },

  [`& .${classes.startingRope}`]: {
    width: 10,
    height: 50,
    background: "#540096",
  },

  [`& .${classes.rope}`]: {
    width: 10,
    height: 10,
    background: "#540096",
  },

  [`& .${classes.hexagonWrapper}`]: {},

  [`& .${classes.timestamp}`]: {
    color: "#540096",
  },

  [`& .${classes.timestampContainer}`]: {
    padding: "10px 10px",
  },

  [`& .${classes.awardImage}`]: {
    position: "absolute",
    left: 0,
    zIndex: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  [`& .${classes.awardStarImage}`]: {
    position: "absolute",
    left: 17.5,
    zIndex: 0,
    top: "43%",
    transform: "translateY(-50%)",
  },
}));

const AwardEvent = ({ direction, timestamp, descriptions, delay }) => {
  const { ref, inView } = useInView({
    threshold: 0.7,
    triggerOnce: true,
  });

  const [showRope, setShowRope] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showTimestamp, setShowTimestamp] = useState(false);

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setShowRope(true);
      }, delay || 0);
      setTimeout(() => {
        setShowDescription(true);
      }, 800 + (delay || 0));
      setTimeout(() => {
        setShowTimestamp(true);
      }, 1200 + (delay || 0));
    }
  }, [inView, delay]);

  return (
    <Root
      style={{
        flexDirection: direction === "down" ? "column" : "column-reverse",
      }}
      ref={ref}
    >
      <Grow in={showRope}>
        <div
          className={classes.startingRope}
          style={{
            height: Array.isArray(descriptions)
              ? descriptions.length === 1
                ? 80
                : descriptions.length === 2
                ? 50
                : 25
              : 50,
          }}
        ></div>
      </Grow>
      {descriptions.map((description) => {
        return (
          <>
            <Grow in={showDescription}>
              <div className={classes.rope}></div>
            </Grow>
            <Grow in={showDescription}>
              <div className={classes.descriptionContainer}>
                <EmojiEventsSharp
                  className={classes.awardImage}
                  sx={{ fontSize: 50, color: "#874db6" }}
                />
                <Star
                  className={classes.awardStarImage}
                  sx={{ fontSize: 15, color: "#967E08" }}
                />
                <Typography className={classes.description}>
                  {description}
                </Typography>
              </div>
            </Grow>
          </>
        );
      })}
      <Grow in={showTimestamp}>
        <div className={classes.timestampContainer}>
          <Typography className={classes.timestamp}>
            {timestamp instanceof Date
              ? new Date(timestamp).toLocaleDateString("en-GB", {
                  year: "numeric",
                })
              : timestamp}
          </Typography>
        </div>
      </Grow>
    </Root>
  );
};

export default AwardEvent;
