import React from "react";

import { styled } from "@mui/material/styles";

import styledComp from "styled-components";

import { Parallax } from "react-scroll-parallax";

const PREFIX = "HexagonPanel";

const classes = {
  root: `${PREFIX}-root`,
  panelContainer: `${PREFIX}-panelContainer`,
  content: `${PREFIX}-content`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  hexagonContainer: `${PREFIX}-hexagonContainer`,
};

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "0px 50px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    padding: "0px 10px",
  },

  [`& .${classes.panelContainer}`]: {
    display: "grid",
    gridTemplateColumns: "115px auto auto 115px",
    gridTemplateRows: "115px 115px 115px",
    gridTemplateAreas: `
      "topleft leftcenter rightcenter topright"
      "middleleft leftcenter rightcenter middleright"
      "bottomleft leftcenter rightcenter bottomright"
    `,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "60px auto auto 60px",
      gridTemplateRows: "60px 220px 60px",
    },
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "100%",
  },

  [`& .${classes.contentWrapper}`]: {
    position: "absolute",
    width: "100%",
    height: "100%",
    padding: "0px 30px",
  },

  [`& .${classes.hexagonContainer}`]: {
    position: "absolute",
    top: "50%",
    width: 250,
    zIndex: -1,
  },
}));

const TopLeftCorner = styledComp.div`
  position: relative;
  overflow: hidden;
  grid-area: topleft;

  &::after {
    content: "";
    position: absolute;
    width: 142%;
    height: 142%;
    transform: rotate(-45deg)
      translateY(calc(29.1% - ${(props) => props.lineWidth / 3}px));
    border-top: ${(props) => props.lineWidth}px solid #540096;
  }
`;

const TopRightCorner = styledComp.div`
  position: relative;
  overflow: hidden;
  grid-area: topright;

  &::after {
    content: "";
    position: absolute;
    width: 142%;
    height: 142%;
    right: 0;
    top: 0;
    transform: rotate(45deg)
      translateY(calc(29.1% - ${(props) => props.lineWidth / 3}px));
    border-top: ${(props) => props.lineWidth || 5}px solid #540096;
  }
`;

const BottomRightCorner = styledComp.div`
  position: relative;
  overflow: hidden;
  grid-area: bottomright;

  &::after {
    content: "";
    position: absolute;
    width: 142%;
    height: 142%;
    right: 0;
    bottom: 0;
    transform: rotate(-45deg)
      translateY(calc(-29.1% + ${(props) => props.lineWidth / 3}px));
    border-bottom: ${(props) => props.lineWidth || 5}px solid #540096;
  }
`;

const BottomLeftCorner = styledComp.div`
  position: relative;
  overflow: hidden;
  grid-area: bottomleft;

  &::after {
    content: "";
    position: absolute;
    width: 142%;
    height: 142%;
    left: 0;
    bottom: 0;
    transform: rotate(45deg)
      translateY(calc(-29.1% + ${(props) => props.lineWidth / 3}px));
    border-bottom: ${(props) => props.lineWidth || 5}px solid #540096;
  }
`;

const MiddleRight = styledComp.div`
  position: relative;
  overflow: hidden;
  grid-area: middleright;
  border-right: ${(props) => props.lineWidth || 5}px solid #540096;
`;

const MiddleLeft = styledComp.div`
  position: relative;
  overflow: hidden;
  grid-area: middleleft;
  border-left: ${(props) => props.lineWidth || 5}px solid #540096;
`;

const CenterLeft = styledComp.div`
  position: relative;
  overflow: hidden;
  grid-area: leftcenter;
  border-top: ${(props) => props.lineWidth || 5}px solid #540096;
  border-bottom: ${(props) => props.lineWidth || 5}px solid #540096;
`;

const CenterRight = styledComp.div`
  position: relative;
  overflow: hidden;
  grid-area: rightcenter;
  border-top: ${(props) => props.lineWidth || 5}px solid #540096;
  border-bottom: ${(props) => props.lineWidth || 5}px solid #540096;
`;

const HexagonPanel = ({
  lineWidth = 3,
  panels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  upset,
  children,
  hexagonFill,
  align,
}) => {
  return (
    <Root>
      <div
        className={classes.panelContainer}
        style={{
          marginTop: upset ? -lineWidth : 0,
        }}
      >
        {panels.includes(1) && <TopLeftCorner lineWidth={lineWidth} />}
        {panels.includes(2) && <CenterLeft lineWidth={lineWidth} />}
        {panels.includes(3) && <CenterRight lineWidth={lineWidth} />}
        {panels.includes(4) && <TopRightCorner lineWidth={lineWidth} />}
        {panels.includes(8) && <MiddleLeft lineWidth={lineWidth} />}
        {panels.includes(5) && <MiddleRight lineWidth={lineWidth} />}
        {panels.includes(7) && <BottomLeftCorner lineWidth={lineWidth} />}
        {panels.includes(6) && <BottomRightCorner lineWidth={lineWidth} />}
        <div className={classes.contentWrapper}>
          <div className={classes.content}>{children}</div>
          <Parallax
            y={["-50px", "50px"]}
            className={classes.hexagonContainer}
            styleOuter={{
              left: align === "left" ? 0 : "",
              right: align === "right" ? 0 : "",
            }}
          >
            <img
              src={hexagonFill}
              style={{
                position: "absolute",
                left: align === "left" ? 0 : "",
                right: align === "right" ? 0 : "",
                width: "100%",
                transform:
                  align === "left"
                    ? `translate(-200px, -50%) rotate(45deg)`
                    : `translate(200px, -50%) rotate(-45deg)`,
              }}
              alt={"Panel Hexagon"}
            />
          </Parallax>
        </div>
      </div>
    </Root>
  );
};

export default HexagonPanel;
