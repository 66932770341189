import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";

import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// sections
import HexagonTimeLine from "../components/about-page/hexagon-time-line-v2";
import Quotes from "../components/about-page/quotes";
import FoldOutAbout from "../components/about-page/fold-out-about";

const Root = styled.main`
  padding: 0px;
  width: 100vw;
  background: white;
  overflow: hidden;
  margin-top: 65px;

  @media (max-width: 600px) {
    margin-top: 57px;
  }

  @media (max-height: 500px) {
    margin-top: 49px;
  }
`;

// markup
const IndexPage = (props) => {
  return (
    <Root>
      <ParallaxProvider>
        <FoldOutAbout images={props.data} />
        <HexagonTimeLine images={props.data} />
        <Quotes images={props.data} />
      </ParallaxProvider>
    </Root>
  );
};

export function Head() {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>About Us - UtterBerry</title>
      <meta
        name='description'
        content='About Page - UtterBerry is a provider of Artificially Intelligent wireless sensor systems for the development of Smart Cities and the revolutionisation of sensing capabilities.'
      ></meta>
    </Helmet>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "about-images/sensor-3.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 340, height: 250)
      }
    }
    imageTwo: file(relativePath: { eq: "about-images/ai-3.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 340, height: 450)
      }
    }
    imageThree: file(relativePath: { eq: "about-images/io.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 300, height: 400)
      }
    }
    imageFour: file(relativePath: { eq: "about-images/london-working.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 200, height: 150)
      }
    }
    imageFive: file(relativePath: { eq: "about-images/heba-podium.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 180, height: 370)
      }
    }
    utterberrySensor: file(relativePath: { eq: "utterberry-stroke.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    networkRail: file(
      relativePath: { eq: "timeline-images/network-rail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    londonUnderground: file(
      relativePath: { eq: "timeline-images/london-underground.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    utterHealth: file(
      relativePath: { eq: "timeline-images/utter-health.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    hongKong: file(relativePath: { eq: "timeline-images/cedd.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    utterSports: file(
      relativePath: { eq: "timeline-images/utter-sports.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    thamesTideway: file(relativePath: { eq: "timeline-images/thames.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    singapore: file(relativePath: { eq: "timeline-images/singapore.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    crossrail: file(relativePath: { eq: "timeline-images/crossrail.jpeg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    tiger: file(relativePath: { eq: "timeline-images/tiger-logo.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    bubbleOne: file(relativePath: { eq: "quote-images/bubble-1.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    bubbleTwo: file(relativePath: { eq: "quote-images/bubble-2.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
  }
`;
