import React from "react";

import { useTheme, useMediaQuery } from "@mui/material";

import HexagonPanel from "./hexagon-panel";
import HexagonEvent from "./hexagon-event";
import AwardEvent from "./award-event";

import HexagonOne from "../../images/utterberry-paints/hex-4.webp";
import HexagonTwo from "../../images/utterberry-paints/hex-5.webp";
import HexagonThree from "../../images/utterberry-paints/hex-6.webp";

import LinkTimeline from "../../images/branches/link-timeline.svg";

const LINE_WIDTH = 10;

const milestones = [
  {
    timestamp: new Date("2013"),
    direction: "down",
    mediumDirection: "up",
    smallDirection: "down",
    description:
      "UtterBerry Sensor System developed by Heba Bevan at Cambridge University, department of Engineering.",
  }, // 0
  {
    timestamp: "2013 - 2018",
    direction: "up",
    mediumDirection: "up",
    smallDirection: "up",
    description:
      "UtterBerry commence work with Crossrail at Farringdon, Moorgate, Liverpool Street, Barbican, Paddington, Mile End, Pudding Mill Lane and Stepney Green Junction stations.",
  }, // 1
  {
    timestamp: "2013 - 2019",
    direction: "up",
    mediumDirection: "up",
    smallDirection: "down",
    description:
      "UtterBerry work with Network Rail to monitor vibrations at Birmingham New Street, HOPS electrification of main rail lines at Swindon, people/car/animal movement at Chichester & Nutbourne level crossings.",
  }, // 2
  {
    timestamp: "2013 - 2018",
    direction: "up",
    mediumDirection: "down",
    smallDirection: "up",
    description:
      "UtterBerry work with London Underground to monitor the integrity of the Post Office Tunnel and cutting monitoring at Chigwell station.",
  }, // 3
  {
    timestamp: "2015 - 2019",
    direction: "up",
    mediumDirection: "up",
    smallDirection: "down",
    description:
      "UtterBerry work with Thames Tideway to monitor various locations including Tower Bridge, Vauxhall Bridge, Albert Bridge, Blackfriars Bridge, Chambers Wharf and Greenwich Pumping station.",
  }, // 4
  {
    timestamp: "2016 - Current",
    direction: "down",
    mediumDirection: "up",
    smallDirection: "up",
    description:
      "UtterBerry create their first medical device called the UtterBerry Multi-Symptom Scanner, providing a 30 second spot-check to help identify and screen persons entering a building with infectious diseases.",
  }, // 5
  {
    timestamp: "2017 - 2020",
    direction: "up",
    mediumDirection: "up",
    smallDirection: "down",
    description:
      "UtterBerry branch out to Hong Kong to work with MTR on the Lyric Theatre Building and three landslide monitoring sites with CEDD.",
  }, // 6
  {
    timestamp: "2017 - Current",
    direction: "up",
    mediumDirection: "up",
    smallDirection: "up",
    description: `UtterBerry branch out to the sports sector, monitoring crowd control and deploying blockchain payment systems at Ascot Racecource events and monitoring Athlete performance for Rugby and Cricket players.`,
  }, // 7
  {
    timestamp: "2018 - Current",
    direction: "down",
    mediumDirection: "up",
    smallDirection: "up",
    description:
      "UtterBerry works with LTA - Singapore to monitor the current construction on the Kampong Java Flyover.",
  }, // 8
  {
    timestamp: "2020 - 2021",
    direction: "up",
    mediumDirection: "up",
    smallDirection: "down",
    description: `UtterBerry commence work on a new passenger security system for UK airports (TIGER), using Blockchain and smart sensor technology.`,
  }, // 9
];

const awards = [
  {
    timestamp: new Date("2013"),
    direction: "down",
    description:
      "Crossrail Best Practice & Innovation Award for the contractors Costain-Skanska.",
  },
  {
    timestamp: new Date("2014"),
    direction: "down",
    description:
      "Ground Investigation and Monitoring Award, NCE International Tunnelling and Underground Space Awards.",
  },
  {
    timestamp: new Date("2014"),
    direction: "down",
    description:
      "Digital Innovation Award, Premier Award, Chartered Institute of Building (CIOB) International Innovation and Research Awards.",
  },
  {
    timestamp: new Date("2015"),
    direction: "up",
    description: "Regional and International Construction Excellence Award.",
  },
  {
    timestamp: new Date("2015"),
    direction: "down",
    description:
      "Product and Equipment Innovation Award & Technical Excellence, Ground Engineering Awards.",
  },
  {
    timestamp: new Date("2015"),
    direction: "down",
    description: "Innovation Award, Constructing Excellence National Awards.",
  },
  {
    timestamp: new Date("2018"),
    direction: "down",
    description:
      "UtterBerry™ has been selected as a champion of British technology excellence through the TechHub programme.",
  },
  {
    timestamp: new Date("2019"),
    direction: "up",
    description:
      "UtterBerry have been awarded the Most innovative rail Season Technology prize in London Transport.",
  },
  {
    timestamp: new Date("2020"),
    direction: "down",
    mediumDirection: "up",
    description: "UtterBerry and Ascot nominated for Sports Technology Award.",
  },
  {
    timestamp: new Date("2021"),
    direction: "up",
    description:
      "UtterBerry has been nominated for the NBCC technological Innovation or the year.",
  },
  {
    timestamp: new Date("2021"),
    direction: "down",
    description:
      "UtterBerry won best London hardware company and Best startup in UK .",
  },
];

const LargeTimeline = (props) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-60%, -95%)",
        }}
      >
        <img
          src={LinkTimeline}
          style={{
            width: 160,
          }}
          alt={"Track Link to Timeline"}
        />
      </div>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[1, 2, 7, 8]}
        hexagonFill={HexagonTwo}
        align={"right"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.utterberrySensor}
            direction={milestones[0].direction}
            timestamp={milestones[0].timestamp}
            description={milestones[0].description}
          />
          <HexagonEvent
            imageSrc={props.images.crossrail}
            direction={milestones[1].direction}
            timestamp={milestones[1].timestamp}
            description={milestones[1].description}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[3, 4, 5, 6]}
        hexagonFill={HexagonOne}
        align={"left"}
        upset
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.networkRail}
            direction={milestones[2].direction}
            timestamp={milestones[2].timestamp}
            description={milestones[2].description}
          />
          <HexagonEvent
            imageSrc={props.images.londonUnderground}
            direction={milestones[3].direction}
            timestamp={milestones[3].timestamp}
            description={milestones[3].description}
          />
          <AwardEvent
            imageSrc={props.images.eventOne}
            direction={awards[0].direction}
            timestamp={awards[0].timestamp}
            descriptions={[awards[0].description]}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[1, 2, 7, 8]}
        hexagonFill={HexagonThree}
        align={"right"}
        upset
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <AwardEvent
            direction={awards[1].direction}
            timestamp={awards[1].timestamp}
            descriptions={[awards[1].description, awards[2].description]}
          />
          <HexagonEvent
            imageSrc={props.images.thamesTideway}
            direction={milestones[4].direction}
            timestamp={milestones[4].timestamp}
            description={milestones[4].description}
          />
          <AwardEvent
            direction={awards[3].direction}
            timestamp={awards[3].timestamp}
            descriptions={[
              awards[3].description,
              awards[4].description,
              awards[5].description,
            ]}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[3, 4, 5, 6]}
        hexagonFill={HexagonOne}
        align={"left"}
        upset
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.utterSports}
            direction={milestones[7].direction}
            timestamp={milestones[7].timestamp}
            description={milestones[7].description}
          />
          <HexagonEvent
            imageSrc={props.images.hongKong}
            direction={milestones[6].direction}
            timestamp={milestones[6].timestamp}
            description={milestones[6].description}
          />
          <HexagonEvent
            imageSrc={props.images.utterHealth}
            direction={milestones[5].direction}
            timestamp={milestones[5].timestamp}
            description={milestones[5].description}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[1, 2, 7, 8]}
        hexagonFill={HexagonThree}
        align={"right"}
        upset
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <AwardEvent
            direction={awards[6].direction}
            timestamp={awards[6].timestamp}
            descriptions={[awards[6].description]}
          />
          <AwardEvent
            direction={awards[7].direction}
            timestamp={awards[7].timestamp}
            descriptions={[awards[7].description]}
          />
          <HexagonEvent
            imageSrc={props.images.tiger}
            direction={milestones[9].direction}
            timestamp={milestones[9].timestamp}
            description={milestones[9].description}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[3, 4, 5, 6]}
        upset
        hexagonFill={HexagonTwo}
        align={"left"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.singapore}
            direction={milestones[8].direction}
            timestamp={milestones[8].timestamp}
            description={milestones[8].description}
          />

          <AwardEvent
            direction={awards[8].direction}
            timestamp={awards[8].timestamp}
            descriptions={[awards[8].description]}
          />
          <AwardEvent
            direction={awards[9].direction}
            timestamp={awards[9].timestamp}
            descriptions={[awards[9].description, awards[10].description]}
          />
        </div>
      </HexagonPanel>
    </>
  );
};

const MediumTimeline = (props) => {
  // this is a quick fix and should not reflect me as a person
  const moveLeft = useMediaQuery("(max-width: 800px)");

  return (
    <>
      <div
        style={{
          position: "absolute",
          left: moveLeft ? "35px" : "50%",
          transform: moveLeft
            ? "translate(0%, -95.5%)"
            : "translate(-58%, -95.5%)",
        }}
      >
        <img
          src={LinkTimeline}
          style={{
            width: 160,
          }}
          alt={"Track Link to Timeline"}
        />
      </div>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={moveLeft ? [2, 4, 5, 6, 3] : [4, 5, 6, 3]}
        hexagonFill={HexagonTwo}
        align={"right"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={
              moveLeft ? props.images.utterberrySensor : props.images.crossrail
            }
            direction={milestones[moveLeft ? 0 : 1].direction}
            timestamp={milestones[moveLeft ? 0 : 1].timestamp}
            description={milestones[moveLeft ? 0 : 1].description}
          />
          <HexagonEvent
            imageSrc={
              moveLeft ? props.images.crossrail : props.images.utterberrySensor
            }
            direction={milestones[moveLeft ? 1 : 0].direction}
            timestamp={milestones[moveLeft ? 1 : 0].timestamp}
            description={milestones[moveLeft ? 1 : 0].description}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[1, 2, 7, 8]}
        hexagonFill={HexagonOne}
        align={"left"}
        upset
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.londonUnderground}
            direction={milestones[3].mediumDirection || milestones[3].direction}
            timestamp={milestones[3].timestamp}
            description={milestones[3].description}
          />
          <HexagonEvent
            imageSrc={props.images.networkRail}
            direction={milestones[2].direction}
            timestamp={milestones[2].timestamp}
            description={milestones[2].description}
          />
          <AwardEvent
            imageSrc={props.images.eventOne}
            direction={awards[0].direction}
            timestamp={awards[0].timestamp}
            descriptions={[awards[0].description]}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[3, 4, 5, 6]}
        hexagonFill={HexagonThree}
        align={"right"}
        upset
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <AwardEvent
            direction={awards[3].direction}
            timestamp={awards[3].timestamp}
            descriptions={[
              awards[3].description,
              awards[4].description,
              awards[5].description,
            ]}
          />
          <HexagonEvent
            imageSrc={props.images.thamesTideway}
            direction={milestones[4].direction}
            timestamp={milestones[4].timestamp}
            description={milestones[4].description}
          />
          <AwardEvent
            direction={awards[1].direction}
            timestamp={awards[1].timestamp}
            descriptions={[awards[1].description, awards[2].description]}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[1, 2, 7, 8]}
        hexagonFill={HexagonOne}
        align={"left"}
        upset
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.utterHealth}
            direction={milestones[5].direction}
            timestamp={milestones[5].timestamp}
            description={milestones[5].description}
          />
          <HexagonEvent
            imageSrc={props.images.utterSports}
            direction={milestones[7].direction}
            timestamp={milestones[7].timestamp}
            description={milestones[7].description}
          />
          <HexagonEvent
            imageSrc={props.images.hongKong}
            direction={milestones[6].direction}
            timestamp={milestones[6].timestamp}
            description={milestones[6].description}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[3, 4, 5, 6]}
        hexagonFill={HexagonThree}
        align={"right"}
        upset
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.tiger}
            direction={milestones[9].direction}
            timestamp={milestones[9].timestamp}
            description={milestones[9].description}
          />
          <AwardEvent
            direction={awards[7].direction}
            timestamp={awards[7].timestamp}
            descriptions={[awards[7].description]}
          />
          <AwardEvent
            direction={awards[6].direction}
            timestamp={awards[6].timestamp}
            descriptions={[awards[6].description]}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[1, 2, 7, 8]}
        upset
        hexagonFill={HexagonTwo}
        align={"left"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <AwardEvent
            direction={awards[9].direction}
            timestamp={awards[9].timestamp}
            descriptions={[awards[9].description, awards[10].description]}
          />
          <AwardEvent
            direction={awards[8].direction}
            timestamp={awards[8].timestamp}
            descriptions={[awards[8].description]}
          />
          <HexagonEvent
            imageSrc={props.images.singapore}
            direction={milestones[8].direction}
            timestamp={milestones[8].timestamp}
            description={milestones[8].description}
          />
        </div>
      </HexagonPanel>
    </>
  );
};

const SmallTimeline = (props) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          left: "0px",
          transform: "translate(-12.5%, -95.5%)",
          height: "120px",
          overflow: "hidden",
          width: "160px",
        }}
      >
        <img
          src={LinkTimeline}
          style={{
            width: 160,
            bottom: 0,
            left: 0,
            position: "absolute",
          }}
          alt={"Track Link to Timeline"}
        />
      </div>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[2, 3, 4, 5, 6]}
        hexagonFill={HexagonTwo}
        align={"right"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.utterberrySensor}
            direction={milestones[0].smallDirection || milestones[0].direction}
            timestamp={milestones[0].timestamp}
            description={milestones[0].description}
          />
          <HexagonEvent
            imageSrc={props.images.crossrail}
            direction={milestones[1].smallDirection || milestones[1].direction}
            timestamp={milestones[1].timestamp}
            description={milestones[1].description}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[1, 2, 7, 8]}
        hexagonFill={HexagonOne}
        align={"left"}
        upset
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.networkRail}
            direction={milestones[2].smallDirection || milestones[2].direction}
            timestamp={milestones[2].timestamp}
            description={milestones[2].description}
          />
          <HexagonEvent
            imageSrc={props.images.londonUnderground}
            direction={milestones[3].smallDirection || milestones[3].direction}
            timestamp={milestones[3].timestamp}
            description={milestones[3].description}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[3, 4, 5, 6]}
        hexagonFill={HexagonThree}
        align={"right"}
        upset
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.utterHealth}
            direction={milestones[5].smallDirection || milestones[5].direction}
            timestamp={milestones[5].timestamp}
            description={milestones[5].description}
          />
          <HexagonEvent
            imageSrc={props.images.thamesTideway}
            direction={milestones[4].smallDirection || milestones[4].direction}
            timestamp={milestones[4].timestamp}
            description={milestones[4].description}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[1, 2, 7, 8]}
        upset
        hexagonFill={HexagonTwo}
        align={"left"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.hongKong}
            direction={milestones[6].smallDirection || milestones[6].direction}
            timestamp={milestones[6].timestamp}
            description={milestones[6].description}
          />
          <HexagonEvent
            imageSrc={props.images.utterSports}
            direction={milestones[7].smallDirection || milestones[7].direction}
            timestamp={milestones[7].timestamp}
            description={milestones[7].description}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[3, 4, 5, 6]}
        upset
        hexagonFill={HexagonOne}
        align={"right"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.singapore}
            direction={milestones[8].smallDirection || milestones[8].direction}
            timestamp={milestones[8].timestamp}
            description={milestones[8].description}
          />
        </div>
      </HexagonPanel>
      <HexagonPanel
        lineWidth={LINE_WIDTH}
        panels={[1, 2, 7, 8]}
        upset
        hexagonFill={HexagonThree}
        align={"left"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          <HexagonEvent
            imageSrc={props.images.tiger}
            direction={milestones[9].smallDirection || milestones[9].direction}
            timestamp={milestones[9].timestamp}
            description={milestones[9].description}
          />
        </div>
      </HexagonPanel>
    </>
  );
};

const HexagonTimeLine = (props) => {
  const theme = useTheme();

  // queries
  const large = useMediaQuery(theme.breakpoints.up("md"));
  const medium = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        padding: "50px 0px",
        backgroundColor: "white",
        width: "100%",
        position: "relative",
        zIndex: 0,
      }}
    >
      {large && <LargeTimeline images={props.images} />}
      {medium && <MediumTimeline images={props.images} />}
      {small && <SmallTimeline images={props.images} />}
      <div
        style={{
          position: "absolute",
          width: "100%",
          bottom: 50,
        }}
      >
        <svg
          style={{
            position: "absolute",
            left: "50%",
            transform: !large
              ? "translate(calc(-50% + 11px), calc(-50% - 4px))"
              : "translate(calc(-50% - 11px), calc(-50% - 4px))",
            width: 46,
            height: 46,
          }}
        >
          <circle
            cx='23'
            cy='23'
            r='15'
            stroke='#540096'
            strokeWidth='8'
            fill='none'
          />
        </svg>
      </div>
    </div>
  );
};

export default HexagonTimeLine;
