import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Typography, Grow } from "@mui/material";

import { useInView } from "react-intersection-observer";

const PREFIX = "HexagonEvent";

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  hexagonContainer: `${PREFIX}-hexagonContainer`,
  hexagonContent: `${PREFIX}-hexagonContent`,
  descriptionContainer: `${PREFIX}-descriptionContainer`,
  description: `${PREFIX}-description`,
  rope: `${PREFIX}-rope`,
  hexagonWrapper: `${PREFIX}-hexagonWrapper`,
  timestamp: `${PREFIX}-timestamp`,
  timestampContainer: `${PREFIX}-timestampContainer`,
};

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  minWidth: 150,
  maxWidth: "33%",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "50%",
  },

  [`& .${classes.image}`]: {
    width: 100,
    height: 117,
    objectFit: "contain",
    clipPath: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
    transform: "translate(0%, -28.4%)",
    background: "white",
    padding: "15px 5px",
  },

  [`& .${classes.hexagonContainer}`]: {
    position: "relative",
    width: "120px",
    height: "69.28px",
    backgroundColor: "#540096",
    margin: "34.64px 0",
    boxShadow: "0 0 10px rgba(0,0,0,0.6)",
    "&::after": {
      content: `""`,
      position: "absolute",
      zIndex: 1,
      width: "84.85px",
      height: "84.85px",
      transform: "scaleY(0.5774) rotate(-45deg)",
      backgroundColor: "inherit",
      left: "18.0736px",
      boxShadow: "0 0 10px rgba(0,0,0,0.6)",
      bottom: "-42.4264px",
    },
    "&::before": {
      content: `""`,
      position: "absolute",
      zIndex: 1,
      width: "84.85px",
      height: "84.85px",
      transform: "scaleY(0.5774) rotate(-45deg)",
      backgroundColor: "inherit",
      left: "18.0736px",
      boxShadow: "0 0 10px rgba(0,0,0,0.6)",
      top: "-42.4264px",
    },
  },

  [`& .${classes.hexagonContent}`]: {
    display: "block",
    position: "absolute",
    top: "0px",
    left: 1,
    width: "120px",
    height: "69.2820px",
    zIndex: 2,
    background: "inherit",
    padding: 10,
  },

  [`& .${classes.descriptionContainer}`]: {
    background: "#eee6f5",
    padding: 10,
    borderRadius: 5,
    boxShadow: "0 0 10px #874db6",
    width: "92%",
    maxWidth: 320,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: 5,
    },
  },

  [`& .${classes.description}`]: {
    color: "black",
    textAlign: "center",
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 11,
    },
  },

  [`& .${classes.rope}`]: {
    width: 10,
    height: 30,
    background: "#540096",
  },

  [`& .${classes.hexagonWrapper}`]: {},

  [`& .${classes.timestamp}`]: {
    color: "#540096",
  },

  [`& .${classes.timestampContainer}`]: {
    padding: 10,
  },
}));

const HexagonEvent = ({
  imageSrc,
  direction,
  timestamp,
  description,
  delay,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.7,
    triggerOnce: true,
  });

  const [showRope, setShowRope] = useState(false);
  const [showHex, setShowHex] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showTimestamp, setShowTimestamp] = useState(false);

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setShowRope(true);
      }, delay || 0);
      setTimeout(() => {
        setShowHex(true);
      }, 400 + (delay || 0));
      setTimeout(() => {
        setShowDescription(true);
      }, 800 + (delay || 0));
      setTimeout(() => {
        setShowTimestamp(true);
      }, 1200 + (delay || 0));
    }
  }, [inView, delay]);

  const gImage = getImage(imageSrc);

  return (
    <Root
      style={{
        flexDirection: direction === "down" ? "column" : "column-reverse",
      }}
      ref={ref}
    >
      <Grow in={showRope}>
        <div className={classes.rope}></div>
      </Grow>
      <Grow in={showHex}>
        <div
          className={classes.hexagonWrapper}
          style={{
            marginTop: direction === "down" ? -10 : 0,
            marginBottom: direction === "up" ? -10 : 0,
          }}
        >
          <div className={classes.hexagonContainer}>
            <span className={classes.hexagonContent}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <GatsbyImage
                  image={gImage}
                  className={classes.image}
                  objectFit={"contain"}
                  width={100}
                  height={100}
                  imgStyle={{
                    padding: "15px",
                  }}
                  alt={`Timeline Image`}
                />
              </div>
            </span>
          </div>
        </div>
      </Grow>
      <Grow in={showDescription}>
        <div
          className={classes.descriptionContainer}
          style={{
            marginTop: direction === "down" ? -35 : 0,
            marginBottom: direction === "up" ? -35 : 0,
            paddingTop: direction === "down" ? 40 : 10,
            paddingBottom: direction === "up" ? 40 : 10,
          }}
        >
          <Typography className={classes.description}>{description}</Typography>
        </div>
      </Grow>
      <Grow in={showTimestamp}>
        <div className={classes.timestampContainer}>
          <Typography className={classes.timestamp}>
            {timestamp instanceof Date
              ? new Date(timestamp).toLocaleDateString("en-GB", {
                  year: "numeric",
                })
              : timestamp}
          </Typography>
        </div>
      </Grow>
    </Root>
  );
};

export default HexagonEvent;
